import { getEnv } from '$environments';
import { setupNewRelic, track } from '$lib/helpers/newRelic';
import { setupPendo } from '$lib/helpers/pendo.js';

setupNewRelic();
export const env = getEnv();
if (env.pendoApiKey) {
	setupPendo(env.pendoApiKey);
}

export const handleError = async ({ error, event, status, message }) => {
	track.exception(error, { event, status, message });
	return error;
};
